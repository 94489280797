import React from 'react'
import { Container, Heading, Text, VStack } from '@chakra-ui/react'
import HeroHeaderNav from './HeroHeaderNav'
import HorizontalGallery from './HorizontalGallery'
import { useEventContext } from '../../../contexts/EventProvider'
import { collectibles } from './data/collectibles.data'
import { experiences } from './data/experiences.data'
import { celebrity } from './data/celebrity.data'
import { cartoonize } from './data/cartoonize.data'
import { gifts } from './data/gifts.data'
import { posters } from './data/posters.data'

const ContributorView = () => {
	const { event, isOwner } = useEventContext()

	console.log('Event:', event, 'IS OWNER:', isOwner)
	return (
		<>
			<HeroHeaderNav
				isGiftShop={true}
				path="/assets/images/marketplace/hero/vidday-video-gift-shop-ons-hero"
				extension="png">
				<VStack>
					<Heading
						w={['250px', 'full']}
						textAlign={['center', 'left']}
						m={['-1.5rem auto -1rem', 'auto auto auto 0']}
						as="h1"
						fontSize={['2.75rem', '3.75rem']}
						variant="hero"
						color="white">
						Gift Shop
					</Heading>
					<Text
						w={['250px', 'full']}
						textAlign={['center', 'left']}
						as="h3"
						fontSize={['1.25rem', '1.5rem']}
						color="white">
						Find unique gifts they'll love.
					</Text>
				</VStack>
			</HeroHeaderNav>

			<Container
				variant="marketplace"
				mt={
					isOwner || !event
						? ['calc(200px + 2rem)', 'calc(248px + 2rem)'] // 144px + 2rem
						: ['calc(280px + 2rem)', 'calc(328px + 2rem)'] // 224px + 2rem
				}>
				<HorizontalGallery heading="Book a celebrity shoutout" collection={celebrity} />

				<HorizontalGallery heading="Get collectibles &amp; merchandise" collection={collectibles} />

				<HorizontalGallery heading="Get unique gifts" collection={gifts} />

				<HorizontalGallery heading="Become a cartoon character" collection={cartoonize} />

				<HorizontalGallery heading="Gift an experience" collection={experiences} />

				<HorizontalGallery heading="Get custom maps &amp; posters" collection={posters} />
			</Container>
		</>
	)
}

export default ContributorView
